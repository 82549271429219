@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Rubik:400,700');


// Update some of Bulma's component variables
$body-background-color: #fff;
$body-family: 'Rubik';

// Import only what you need from Bulma
@import '../node_modules/bulma/sass/utilities/_all.sass';
@import '../node_modules/bulma/sass/base/_all.sass';

@import '../node_modules/bulma/sass/grid/_all.sass';

@import '../node_modules/bulma/sass/elements/box.sass';
@import '../node_modules/bulma/sass/elements/button.sass';
@import '../node_modules/bulma/sass/elements/container.sass';
@import '../node_modules/bulma/sass/elements/form.sass';
@import '../node_modules/bulma/sass/elements/title.sass';

@import '../node_modules/bulma/sass/components/navbar.sass';
@import '../node_modules/bulma/sass/components/card.sass';
@import '../node_modules/bulma/sass/components/modal.sass';

@import '../node_modules/bulma/sass/layout/hero.sass';
@import '../node_modules/bulma/sass/layout/section.sass';


// Custom Styles

$brand-color: #261D5B;

.navbar, .navbar-menu {
  background-color: $brand-color; }
.navbar.has-shadow {
  box-shadow: 0px 3px 3px 0 grey; }

.navbar-item, .navbar-burger, .navbar-item>a {
  color: #fff; }
a.navbar-item:hover, a.navbar-item:active, a.navbar-item:focus {
  color: #CACACA; }

.navbar-brand>.navbar-item {
  font-size: 18pt; }
.navbar-item > a {
  text-transform: uppercase; }

.app-body {
  min-height: 85vh;
  line-height: 1.2;

  h1 {
    font-size: 20pt;
    font-weight: bold; }
  h2 {
    font-size: 18pt;
    font-weight: regular; }

  .app-brand {
    font-size: 22pt;
    font-weight: bold;
    color: $brand-color;
    text-transform: uppercase;
    margin-bottom: 3rem; } }

.pageHeader {
  margin-bottom: 22px;
  h1 {
    font-size: 22pt; } }

// ----------------------------------------------------------------------------------------------------------------------------------------
// Custom Card Styles
// ----------------------------------------------------------------------------------------------------------------------------------------
.card-content {
  line-height: 1.6em;
  h1,h2,h3 {
    margin-bottom: 18px;
    font-weight: bold; } }

.item.card {
  height: 300px;
  overflow-y: hidden;
  border: 1px solid #C0C0C0;
  border-radius: 10px;
  box-shadow: 3px 5px 5px 0 #DDDDDD; }
.card img.card-banner {
  height: 75px;
  width: 100%; }

// ----------------------------------------------------------------------------------------------------------------------------------------
// Custom Slide Show Card Styles
// ----------------------------------------------------------------------------------------------------------------------------------------
.card.slide-show-item {
  height: auto; }
.slide-show-preview {
  width: 100%; }


.clickable-card:hover {
  cursor: pointer; }

// Article Specific Styles
.article-page {
  h1 {
    font-size: 2.5rem; }
  h2 {
    font-size: 2rem; }
  h3 {
    font-size: 1.75rem; }
  h4 {
    font-size: 1.5rem; }
  h1,h2,h3,h4 {
    font-weight: 500;
    margin-bottom: 0.5rem; }

  .article-content {
    line-height: 1.2;
    font-weight: 400;
    p {
      margin-bottom: 16px; }
    blockquote {
      padding: 5px 15px;
      background-color: rgba(229, 235, 232, 1.0);
      margin-bottom: 16px;
      p {
        margin: 0px; } }
    a {
      color: rgba(93, 135, 118, 1.0);
      text-decoration: underline; }
    h1,h2,h3,h4,h5 {
      margin-bottom: 8px; }
    h2 {
      color: rgba(68, 121, 100, 1.0); }
    h4 {
      font-size: 1.5rem;
      margin-bottom: 8px;
      font-weight: 500; }
    ul,ol {
      margin-bottom: 16px;
      li {
        margin-top: 10px; }
      li > p {
        display: inline; } }
    ol {
      list-style-position: inside; }
    ul {
      list-style: inside; } }

  .article-up-next {
    margin-top: 20px;
    padding-top: 30px;
    border-top: 1px dashed $brand-color; } }

.loading-container {
  min-height: 400px; }

// Glossary Specific Modal Styles

.glossary.modal {
  .modal-content {
    padding: 10px;

    .box {
      min-height: 100px;
      padding: 10px; } } }

// Footer styles
.footer.powered-by {
  p {
    padding-top: 10px; } }

// Insta Story Style Series
.story-lightbox {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.65);
  height: 100vh;
  width: 100vw;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  .story-wrapper {}

  .story-dismiss {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold; } }
